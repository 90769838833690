import React from "react"
import LocalizedLink from "../localizedLink"
import useTranslations from "../useTranslations"

const CompanyLeftNav = () => {
    const { contact, faq, catalogDownload, softDownload, documents, customerSupport  } = useTranslations()

    const ActiveStyle = {
        backgroundColor: '#033364',
        color: '#fff'
    }

    const pathName = typeof window !== 'undefined' ? window.location.pathname : '';

    return (
      <>
        <div className="leftnav">
          <ul className="leftnav-links pc">
            <li className="leftnav-link">
              <LocalizedLink activeStyle={ActiveStyle} to={`/support`}>
                {contact}
              </LocalizedLink>
            </li>
            <li className="leftnav-link">
              <LocalizedLink activeStyle={ActiveStyle} to={`/support/faq`}>
                {faq}
              </LocalizedLink>
            </li>
            <li className="leftnav-link">
              <LocalizedLink
                activeStyle={ActiveStyle}
                to={`/support/catalog-download`}
              >
                {catalogDownload}
              </LocalizedLink>
            </li>
            <li className="leftnav-link">
              <LocalizedLink
                activeStyle={ActiveStyle}
                to={`/support/software-download`}
              >
                {softDownload}
              </LocalizedLink>
            </li>
            {
              (pathName.indexOf('/en') !== -1) ?
                  null:
                  <li className="leftnav-link">
                    <LocalizedLink
                        activeStyle={ActiveStyle}
                        to={`/support/documents`}
                    >
                      {documents}
                    </LocalizedLink>
                  </li>
            }
          </ul>

          <div className={`ac sp`}>
            <input
              className="ac-input"
              id="contact-info"
              name="contact-info"
              type="checkbox"
            />
            <label className="ac-label" htmlFor="contact-info">
              {customerSupport}
            </label>
            <section className={` ac-text ac-text-footer `}>
              <ul className="leftnav-links">
                <li className="leftnav-link">
                  <LocalizedLink to={`/support`}>{contact}</LocalizedLink>
                </li>
                <li className="leftnav-link">
                  <LocalizedLink to={`/support/faq`}>{faq}</LocalizedLink>
                </li>
                <li className="leftnav-link">
                  <LocalizedLink to={`/support/catalog-download`}>
                    {catalogDownload}
                  </LocalizedLink>
                </li>
                <li className="leftnav-link">
                  <LocalizedLink to={`/support/software-download`}>
                    {softDownload}
                  </LocalizedLink>
                </li>
                {
                  (pathName.indexOf('/en') !== -1) ?
                      null:
                      <li className="leftnav-link">
                        <LocalizedLink to={`/support/documents`}>
                          {documents}
                        </LocalizedLink>
                      </li>
                }
              </ul>
            </section>
          </div>
        </div>
      </>
    );
}

export default CompanyLeftNav

